<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div v-if="educationInfo.plantName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="LBLPLANT" :value="educationInfo.plantName"></c-label-text>
        </div>
        <!-- 교육과정 -->
        <div v-if="educationInfo.educationCourseName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="LBL0002930" :value="educationInfo.educationCourseName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6M"
            defaultEnd="0M"
            label="LBL0002829"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <!-- 교육과정별 교육계획 및 결과 -->
      <c-table
        ref="table"
        title="LBL0002810"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :editable="editable"
        :merge="grid.merge"
        rowKey="eduEducationId"
        selection="multiple"
        @linkClick="linkClick"
      >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <q-btn
              icon="help"
              color="deep-purple-6"
              text-color="white"
              class="custom-btn"
              align="center"
              round>
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop">
                  <!-- 대상자 업로드 설명 -->
                  {{$label('LBL0002830')}}
                </div>
                <div class="tooltipCustom">
                  <div class="q-pl-xs" style="text-align: left">
                    <!-- 1. 교육과정별 교육계획을 선택합니다.(단, 교육완료 된 교육은 선택할 수 없습니다.) -->
                    <span>{{$label('LBL0002831')}}</span>
                    <br/>
                    <!-- 2. [교육계획별 대상자 템플릿 다운로드] 버튼을 클릭합니다. -->
                    <span>{{$label('LBL0002832')}}</span>
                    <br/>
                    <!-- 3. [교육대상자 업로드] 버튼을 클릭합니다. -->
                    <span>{{$label('LBL0002833')}}</span>
                    <br/>
                    <!-- 4. 다운된 파일로 엑셀 업로드를 하여 적용을 시킵니다. -->
                    <span>{{$label('LBL0002834')}}</span>
                  </div>
                </div>
              </q-tooltip>
            </q-btn>
            <!-- 1. 교육계획별 대상자 템플릿 다운로드 -->
            <c-btn v-if="editable" label="LBL0002835" icon="print" @btnClicked="print" />
            <!-- 2. 교육대상자 업로드-->
            <c-btn v-if="editable" label="LBL0002836" :editable="editable" icon="assignment" @btnClicked="openExcelUploader"/>
            <!-- 교육과정별 대상자-->
            <c-btn v-if="editable && grid.data.length > 0" label="LBL0002837" icon="check" @btnClicked="select" />
            <!-- 교육계획 추가-->
            <c-btn v-if="editable" label="LBL0002838" icon="add" @btnClicked="onItemClick('A')" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH"  icon="search" @btnClicked="getHistoryList" />
          </q-btn-group>
        </div>
      </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>

import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
import XLSX from 'xlsx'
export default {
  name: 'education-history',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      educationInfo: {
        plantName: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: "educationName",
            field: "educationName",
            // 교육명
            label: "LBL0002929",
            style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationDate",
            field: "educationDate",
            // 교육기간
            label: "LBL0002829",
            style: 'width:250px',
            align: "center",
            sortable: true,
          },
          {
            name: 'educationLocation',
            field: 'educationLocation',
            // 교육장소
            label: 'LBL0002839',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            // 교육방법
            label: "LBL0002936",
            align: "center",
            sortable: true,
          },
          // {
          //   name: "evaluationEvalName",
          //   field: "evaluationEvalName",
          //   style: 'width:90px',
          //   label: "교육평가",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "educationOverview",
          //   field: "educationOverview",
          //   style: 'width:200px',
          //   label: "교육총평",
          //   align: "left",
          //   sortable: true,
          // },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            style: 'width:300px',
            // 학습목적
            label: "LBL0002937",
            align: "left",
            sortable: true,
          },
        ],
        height: '500px',
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      period: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'count'() {
      this.getList();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.course.get.url;
      // this.getStdUrl = selectConfig.sop.edu.attendee.get.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);
          this.updateMode = true;
          this.getHistoryList();
        },);
      }
    },
    getHistoryList() {
      if (this.popupParam.eduCourseId) {
        let _startYmd = '';
        let _endYmd = '';
        if (this.period) {
          _startYmd = this.period[0];
          _endYmd = this.period[1];
        } else {
          _startYmd = '';
          _endYmd = '';
        }
        this.$http.url = selectConfig.sop.edu.result.list.url
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.educationInfo.plantCd,
          eduCourseId: this.popupParam.eduCourseId,
          startYmd: _startYmd,
          endYmd: _endYmd,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data
        },);
      }
    },
    addItem() {
      this.grid.data.splice(0, 0, {
        edcuationCourseSeq: 0,
        chkYmd: '',
        deptCd: '',
        chkResultDetail: '', 
        chkResult: '', 
        fileNm: '', 
      })
    },
    removeItem() {
      let selectData = this.$refs['hazardEquipmentInsp'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0002840"; // 교육계획 및 결과 상세
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getHistoryList();
    },
    onItemClick(data) {
      if (data === 'A') { // 상세
        this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.title = "LBL0002840"; // 교육계획 및 결과 상세
        this.popupOptions.param = {
          eduEducationId: '',
          eduCourseId: this.educationInfo.eduCourseId,
          stepCd: 'ESC0000005',
        };
      }
      this.popupOptions.closeCallback = this.closePopup;
    },
    select() {
      let _startYmd = '';
      let _endYmd = '';
      if (this.period) {
        _startYmd = this.period[0];
        _endYmd = this.period[1];
      } else {
        _startYmd = '';
        _endYmd = '';
      }
      this.popupOptions.title = "LBL0002841"; // 교육과정별 대상자 연명부
      this.popupOptions.param = {
        plantCd: this.educationInfo.plantCd,
        eduCourseId: this.educationInfo.eduCourseId,
        startYmd: _startYmd,
        endYmd: _endYmd,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/educationTargetUsersPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '95%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    print() {
      let selectData = this.$refs['table'].selected;
      if (selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000853', // 교육계획을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        let printData = [];
        this.$_.forEach(selectData, _item => {
          printData.push({
            '교육계획일련번호': _item.eduEducationId,
            '교육명': _item.educationName,
            '교육기간': _item.educationDate,
            '사번': '',
            'LBLNAME': '',
          })
        })
        const worksheet = XLSX.utils.json_to_sheet(printData);
        worksheet["!cols"] = [
          { wpx: 100 },
          { wpx: 400 },
          { wpx: 200 },
          { wpx: 100 },
          { wpx: 100 },
        ]
        const workBook = {
          SheetNames: ['대상자'], // sheet name
          Sheets: {
            '대상자': worksheet,
          }
        };
        XLSX.writeFile(workBook, '업로드용(' + this.educationInfo.educationCourseName + ').xlsx');
      }
    },
      
    openExcelUploader() {
      this.popupOptions.title = 'LBL0002842'; // 교육계획별 대상자 업로드
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./educationCurriculumnExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelPopup;
    },
    closeExcelPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let postData = [];
        this.$_.forEach(s_data, _item => {
           let index = this.$_.findIndex(this.grid.data, {
            empNo: _item.empNo,
            eduEducationId: _item.eduEducationId
          });
          if (index === -1) {
            postData.splice(0, 0, {
              eduEducationId: _item.eduEducationId,
              empNo: _item.empNo,
              attendeesTypeCd: 'EATC00003',
              attendeesName: _item.userName,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
        /* eslint-disable no-unused-vars */ 
        this.$http.url = transactionConfig.sop.edu.plan.insert.exceltargetUsers.url;
        this.$http.type = 'POST';
        this.$http.param = postData;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
  }
};
</script>